<div layout-growl style="z-index: 9999;position: absolute">
  <p-growl [value]="msgs" [sticky]="true" (onClose)="removeGrowlMsg($event)"></p-growl>
</div>

<dashboard-layout *ngIf="!userInfo.loading">

  <form layout-title title-search class="form-title px-h hidden-md-down col"></form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up">
    <form title-search class="form-title"></form>
  </ol>

  <ul layout-sidebar class="nav">
    <sidebar-support></sidebar-support>
  </ul>

  <div *ngIf="loading" layout-main class="animated fadeIn fast">
    Загрузка...
  </div>

  <div layout-extended-search extended-search class="card text-white bg-dark extended-search"
       [class.hidden]="!extendedSearch"></div>

  <div [class.hidden]="loading" layout-main class="animated fadeIn fast ticketsTable"
       (window:resize)="onResize($event)">

    <div>
      <span *ngIf="count == tickets.length">
        {{{
            value: count,
            '0': 'Ничего не найдено',
            '1': 'Найден {} тикет',
            '2': 'Найдены {} тикета',
            '5': 'Найдено {} тикетов'
        } | plural }}
      </span>
      <span *ngIf="count != tickets.length">
        {{{
            value: tickets.length,
            '1': 'Загружен {} тикет',
            '2': 'Загружены {} тикета',
            '5': 'Загружено {} тикетов'
        } | plural }}
        из
        {{{
            value: count,
            '1': '{} найденного',
            '2': '{} найденных',
            '5': '{} найденных'
        } | plural }}
      </span>
    </div>

    <p-table #dt
             [value]="tickets"
             [responsive]="true"
             [(selection)]="selectedTickets"
             [metaKeySelection]="false"
             dataKey="id"
             sortField="activity"
             sortOrder="-1"
             [resetPageOnSort]="true"
             (onSort)="onSort($event)"
             rowHover="true"
             [class.no-summary]="selectedTickets.length == 0"
             [rowsPerPageOptions]="[10,20,50,100]"
             [pageLinks]="5"
             [paginator]="true" [rows]="20">
      <ng-template pTemplate="header">
        <tr class="ui-state-default">
          <th class="ui-state-default ui-unselectable-text" style="width: 2.25em">
            <p-checkbox [binary]="true" [ngModel]="isPageSelected()" (onChange)="togglePageSelection($event)"></p-checkbox>
          </th>
          <th class="w-9r ticketField__link ui-state-default ui-unselectable-text">№</th>
          <th class="w-6r ticketField__editable ui-state-default ui-unselectable-text">Тег</th>
          <th class="w-8r ticketField__none ui-state-default ui-unselectable-text">Клиент</th>
          <th class="w-7r ticketField__editable ui-state-default ui-unselectable-text">Статус</th>
          <th class="w-8r ticketField__editable ui-state-default ui-unselectable-text">Отдел</th>
          <th class="w-11r ticketField__editable ui-state-default ui-unselectable-text">Назначен</th>
          <th class="ticketField__link ui-state-default ui-unselectable-text" style="min-width: 100px">Тема</th>
          <th class="w-9r ticketField__link ui-state-default ui-unselectable-text"
              [pSortableColumn]="'activity'">
            Неактивен
            <p-sortIcon [field]="'activity'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-ticket>
        <tr class="ui-datatable-even ui-widget-content" (click)="ticketClick($event)">

          <td class="ticketField__check" style="width: 2.25em">
            <p-tableCheckbox [value]="ticket"></p-tableCheckbox>
          </td>

          <tooltip #tooltipLastMessage>
            <div style="max-height: 200px; overflow-y: scroll; padding: 5px 10px">
              <div>Всего сообщений <b>{{ticket.messagesCount}}</b>, последнее:</div>
              <message [ticketMessage]="ticket.lastMessage" [noRemove]="true"></message>
            </div>
          </tooltip>

          <td class="w-8r ticketField__link tooltip-grouped" [tooltip]="tooltipLastMessage" [tooltipPosition]="'right'"
              [tooltipWhite]="true" [tooltipMaxWidth]="600" [tooltipInteractive]="true" [tooltipDelay]="[300,300]">
            <a class="font-weight-bold" routerLinkActive="active"
               [routerLink]="['/support/chat', ticket.panelPrefix, ticket.id]">
              {{ticket.mask}}
            </a>
            <span class="mask-postfix">{{ticket.maskPostfix}}</span>
          </td>

          <td class="w-6r ticketField__editable" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown-editor
                  #ddTag
                  (onChange)="changeTag(ticket, $event.value)"
                  [ngModel]="ticket.tag"
                  [options]="getTags(ticket)"
                  [autoWidth]="false"
                  [style]="{'width':'100%'}"
                  (onHide)="blurEditable(ddTag)"
                  (onFocus)="focusEditable(ddTag)"
                  placeholder="Без тега"
                  required="true">
                  <ng-template let-tag pTemplate="item">
                    <span class="badge" [style.background-color]="tag.color">{{tag.label}}</span>
                  </ng-template>
                </p-dropdown-editor>
              </ng-template>
              <ng-template pTemplate="output">
                <span (click)="ticketEdit($event, ticket)" class="ticketField__edit badge"
                      [style.background-color]="ticket?.tag?.color">{{ticket?.tag?.label}}</span>
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="w-7r ticketField__none">
            <span>{{ticket.username}}</span>&nbsp;
            <span class="badge badge-{{ticket.panelPrefix}}">{{ticket.panelPrefix}}</span>
          </td>

          <td class="w-8r ticketField__editable" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown-editor
                  #ddStatus
                  (onChange)="changeStatus(ticket, $event.value)"
                  [ngModel]="ticket.status"
                  [options]="statuses"
                  [autoWidth]="false"
                  [style]="{'width':'100%'}"
                  (onHide)="blurEditable(ddStatus)"
                  (onFocus)="focusEditable(ddStatus)"
                  required="true"></p-dropdown-editor>
              </ng-template>
              <ng-template pTemplate="output">
                <span (click)="ticketEdit($event, ticket)" class="ticketField__edit">{{ticket.status.name}}</span>
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="w-8r ticketField__editable" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown-editor
                  #ddDept
                  (onChange)="changeDepartment(ticket, $event.value)"
                  [ngModel]="ticket.department"
                  [options]="departments"
                  [autoWidth]="false"
                  [style]="{'width':'100%'}"
                  (onHide)="blurEditable(ddDept)"
                  (onFocus)="focusEditable(ddDept)"
                  required="true"></p-dropdown-editor>
              </ng-template>
              <ng-template pTemplate="output">
                <span (click)="ticketEdit($event, ticket)" class="ticketField__edit">{{ticket.department.name}}</span>
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="w-11r ticketField__editable" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <ng-container *ngIf="ticket.assignedTo && !ticket.updating">
                  <p-dropdown-editor
                    #ddAdmins
                    (onChange)="changeAssignTo(ticket, $event.value)"
                    [ngModel]="ticket.assignedTo"
                    [options]="getAvailableAdmins(ticket)"
                    placeholder="Не назначен"
                    [autoWidth]="false"
                    [style]="{'width':'100%'}"
                    (onHide)="blurEditable(ddAdmins)"
                    (onFocus)="focusEditable(ddAdmins)"
                    required="true"></p-dropdown-editor>
                </ng-container>
              </ng-template>
              <ng-template pTemplate="output">
                <span class="ticketField__edit" *ngIf="ticket.assignedTo">{{ticket.assignedTo.userRealName}}</span>
                <div class="ui-grid-row assign-to-me"
                     *ngIf="!ticket.assignedTo"
                     (click)="assignToMe(ticket)">
                  <span class="ui-grid-row assign-to-me-table-link">Назначить на себя</span>
                </div>
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="ticketField__link tooltip-grouped" style="min-width: 100px">
            <a class="" routerLinkActive="active" [routerLink]="['/support/chat', ticket.panelPrefix, ticket.id]">
              {{ticket.subject}}
            </a>
          </td>

          <td class="w-9r ticketField__none">
            <span [class.ticketField__alert]="isTicketOld(ticket.activity)" title="{{ticket.activity}}">{{ticket.activityDiff}}</span>
          </td>


        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div *ngIf="selectedTickets.length > 0" class="text-left">
          <span>С выбранными (<span class="pseudo-link stronger">{{selectedTickets.length}}</span>): </span>
          <button type="button" class="btn btn-primary" (click)="openChatMassReply(selectedTickets)">Ответить</button>
          <button type="button"
                  *ngFor="let status of statuses"
                  class="btn btn-{{status.color}}"
                  [disabled]="isProcessChangeMassStatus()"
                  (click)="changeMassStatus(selectedTickets, status)">{{status.action}}</button>
        </div>
      </ng-template>
    </p-table>

  </div>
</dashboard-layout>
