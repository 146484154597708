import {
    AfterViewInit,
    Component, ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Message} from "../../domain/message.domain";
import "jquery";
import "bootstrap";
import {ChatMessagesDataservice} from "../../data/chat-messages.dataservice";
import {NavigationData, NavigationService} from "../../services/navigation.service";
import {Subject} from "rxjs";
import {GrowlMessageService} from "../../../core/services/growl-message.service";
import {LoginDataservice, UserInfo} from "../../../core/data/login.dataservice";
import {ChatInfoShowLogsEvents} from "../../services/chat-info.show-logs.events";
import {ChatInterface} from "./chat.interface";
import {Message as GrowlMessage} from "primeng/components/common/api";
import {Dialog} from "primeng/components/dialog/dialog";
import "rxjs/add/operator/takeUntil";
import {ChatTextareaDirective} from "./reply/chat-textarea.directive";
import {AsideSupportService} from "./aside/aside-support.service";
import {ChatTextareaEventbus} from "./reply/chat-textarea.eventbus";
import {Store} from "../../store/store";
import {TicketData} from "../../store/ticket.store";
import {ChatInfoShowOnlyNotesEvents} from "../../services/chat-info.show-only-notes.events";
import {CheckService} from "../../services/litera.service";

declare var $: any;

@Component({
    templateUrl: 'chat.component.html'
})
export class ChatComponent implements OnInit, OnDestroy, ChatInterface, AfterViewInit {
    @ViewChild("noteDialog") noteDialog: Dialog;
    @ViewChild('noteText') noteTextArea: ElementRef;

    private _noteDialogDisplay: boolean = false;
    private showLogs: boolean = false;
    private showOnlyNotes: boolean = false;
    private removeMsgDialog: boolean = false;
    private removeMsgId: number;

    private _ticketData: TicketData = null;
    private _ticketId: number;
    private _ticketPanel: string;
    private _userInfo: UserInfo;
    private chatTextareaDirective: ChatTextareaDirective;
    private ngDestroyed: Subject<void> = new Subject<void>();

    private ticketSyncInterval = null;
    private quoteText: string = "";

    constructor(private asideSupportService: AsideSupportService,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private growlMessageService: GrowlMessageService,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService,
                private chatInfoShowLogsEvents: ChatInfoShowLogsEvents,
                private chatInfoShowOnlyNotesEvents: ChatInfoShowOnlyNotesEvents,
                private chatTextareaEventbus: ChatTextareaEventbus,
                private loginDataservice: LoginDataservice,
                private _store: Store,
                private checkService: CheckService) {
    }

    get store() {
        return this._store;
    }

    ngOnInit() {
        this.navigationService.navEnd.takeUntil(this.ngDestroyed).subscribe((n: NavigationData) => {
            this._ticketId = this.activatedRoute.snapshot.params.id;
            this._ticketPanel = this.activatedRoute.snapshot.params.panel;
            this._ticketData = null;
            this.chatInfoShowOnlyNotesEvents.next(false);
            this.chatMessagesDataservice.loadMessages(this._ticketPanel, this._ticketId, true);
        });

        this.store.ticketData.changed.takeUntil(this.ngDestroyed).subscribe(data => {
            this._ticketData = data;
            if (data) {
                setTimeout(() => this.noteDialog.onHide.subscribe(() => this.closeNoteDialog()), 1);
            }

            setTimeout(() => {
                let anchor = this.showAnchor;
                if (!anchor) {
                    anchor = "" + this.store.ticketData.lastMessage.id;
                }
                $('html, body').animate({
                    scrollTop: $("#anchor" + anchor).offset().top - 55 // с поправкой на шапку
                }, 20);
            }, 0);
        });
        this.chatMessagesDataservice.messageSent.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data.sent) {
                this.growlMessageService.success("Отправлено", "Сообщение отправлено");
            }
            if (data.goToDashboard) {
                this.navigationService.goToDashboard();
            } else {
                this.chatTextareaDirective.initMessage("", true);
            }
        });

        this.chatTextareaEventbus.noteDialogSubject.takeUntil(this.ngDestroyed).subscribe(data => this._noteDialogDisplay = data);
        this.asideSupportService.showTabs = ['info'];

        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => this._userInfo = data);
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data != null) {
                this.chatTextareaDirective = data;
                let hasAdminMsg;
                let draftText;
                if (this._ticketData) {
                    hasAdminMsg = this._ticketData.messages.some(msg => msg.authorType == 'admin');
                    draftText = this._ticketData.ticket.draftText;
                }
                this.chatTextareaDirective.initMessage(draftText, hasAdminMsg);
            }
        });

        this.chatInfoShowLogsEvents.subscribe(this.ngDestroyed, (data) => this.showLogs = data);
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, (data) => this.showOnlyNotes = data);
        this.ticketSyncInterval = setInterval(() => {
            this.ticketSynchronization()
        }, 3000);
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
        clearInterval(this.ticketSyncInterval);
    }

    closeNoteDialog(): void {
        this.chatTextareaEventbus.noteDialogSubject.next(false);
    }

    get msgs(): GrowlMessage[] {
        return this.growlMessageService.msgs;
    }

    removeGrowlMsg(event) {
        this.growlMessageService.removeMsg(event.message);
    }

    get noteDialogDisplay(): boolean {
        return this._noteDialogDisplay;
    }

    get ticketData(): TicketData {
        return this._store.ticketData.value;
    }

    ngAfterViewInit() {
    }

    get typingAdmins(): Array<string> {
        return this.store.ticketData.typingAdmins;
    }

    get messages(): Message[] {
        return this.ticketData.messages.filter((m) => {
            if (this.showOnlyNotes) {
                return m.type == 'note'
            }
            return this.showLogs ? true : (m.type != 'log' && m.active)
        });
    }

    get panelPrefix(): string {
        return this.ticketData.ticket.panelPrefix;
    }

    postMessageByHotKey(event, chatMessage: string, postpone: boolean) {
        event.preventDefault();

        this.postMessage(event, chatMessage, postpone)
    }

    reloadData(event) {
        event.preventDefault();
        this.chatMessagesDataservice.loadMessages(this._ticketPanel, this._ticketId, true);
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(evt: KeyboardEvent) {
        if (this.store.ticketData.ticketChanged) {
            this.reloadData(evt);
        }
    }

    ticketSynchronization() {
        if (!this.store.ticketData.value || !this.store.ticketData.ticket || this.isProcessReply()) {
            return;
        }
        let message = $("#message-to-send").val();
        const formData: FormData = this.generateFormData();
        formData.append('ticketVersion', "" + this._ticketData.ticket.version);
        let defaultText = this.chatTextareaDirective.initMessageText.replace(this.chatTextareaDirective.cursorPositionMarker, "");
        if (message == defaultText) {
            // исходный текст = пустой черновик
            message = "";
        } else if (message != null && message != "") {
            // вставляем в черновик позицию курсора
            message = message.slice(0, this.chatTextareaDirective.textareaSelectionStart) +
                this.chatTextareaDirective.cursorPositionMarker +
                message.slice(this.chatTextareaDirective.textareaSelectionStart);
        }
        formData.append('draftMessage', message);

        this.chatMessagesDataservice.ticketSynchronization(formData);
    }

    //в случае редактирования не забыть проверить postMessage метод в chat-new.component.ts
    postMessage(event, chatMessage: string, postpone: boolean) {
        event.preventDefault();
        let inputs = $(".message__form").find("input[type='file']");
        const formData: FormData = this.generateFormData();
        let fileCount = 0;

        if (inputs) {
            let n = 0;
            for (let i = 0; i < inputs.length; i++) {
                let nativeElement = inputs[i];
                let files = nativeElement["files"];
                for (let j = 0; j < files.length; j++) {
                    let file: File = files[j];
                    if (file != undefined && file.size > 0) {
                        formData.append('attachment' + n++, file);
                        fileCount++;
                    }
                }
            }
        }

        if (fileCount == 0 && chatMessage == "") {
            this.growlMessageService.error("Введите сообщение или прикрепите файл");
            return;
        }

        formData.append('type', "message");
        if (postpone != null) {
            formData.append('postpone', postpone ? "true" : "false");
        }
        formData.append('afterPropertyChangeInChat', this.store.ticketData.afterPropertyChangeInChat ? "true" : "false");

        let reply = message => {
            formData.append('message', message);
            this.chatMessagesDataservice.reply(this._ticketPanel, this._ticketId, formData, true, postpone != null);
            this.store.ticketData.clearAfterPropertyChangeInChat();
        };

        if (chatMessage) {
            this.checkService.start({text: chatMessage, ticket: this.ticketData.ticket, onSuccess: reply});
            this.chatMessagesDataservice.processReply = true;
            return;
        }
        reply(chatMessage)
    }

    isProcessReply() {
        return this.chatMessagesDataservice.isProcessReply();
    }

    private generateFormData(): FormData {
        const formData: FormData = new FormData();
        formData.append('ticketId', "" + this._ticketId);
        formData.append('panelPrefix', this.panelPrefix);
        return formData
    }

    postNote(event: Event, chatMessage: string, pin: boolean) {
        event.preventDefault();

        if (chatMessage == "") {
            this.growlMessageService.error("Введите сообщение");
            return;
        }

        const formData: FormData = this.generateFormData();

        formData.append('message', chatMessage);
        formData.append('type', "note");
        formData.append('pin', "" + pin);

        this.chatMessagesDataservice.reply(this._ticketPanel, this._ticketId, formData, false);
        this.closeNoteDialog();
    }

    openRemoveMsgDialog(id: number) {
        this.removeMsgId = id;
        this.removeMsgDialog = true;
    }

    closeRemoveMsgDialog() {
        this.removeMsgDialog = false;
    }

    removeMessage() {
        this.chatMessagesDataservice.removeMessage(this.removeMsgId, this._ticketId, this.panelPrefix);
        this.closeRemoveMsgDialog();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    get showAnchor() {
        return this.activatedRoute.snapshot.params.anchor;
    }

    quotation(text: string = this.quoteText) {
        text = this.decodeText(text.split("\n").map(s => "> " + s).join("\n"));
        this.chatTextareaDirective.insertText(text + "\n\n__cursorPosition__", null, "__cursorPosition__", true);
        this.chatTextareaDirective.focus();
        $('.quote-block').css('visibility', 'hidden');
        return false;
    }

    selectTicketMask(event: Event) {
        let range = document.createRange();
        let el = event.target as Node;
        range.selectNodeContents(el);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    private decodeText(html) { //todo можно вынести в отдельный сервис
        let txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    selectQuote() {
        setTimeout(() => {
            let selection = window.getSelection();
            let quoteText = selection.toString();

            if (quoteText) {
                let range = selection.getRangeAt(0);
                if (range.toString()) {
                    this.quoteText = quoteText;
                    this.setQuoteBlockPosition(range);
                    return;
                }
            }
            $('.quote-block').css('visibility', 'hidden');
        }, 10);
    }

    setQuoteBlockPosition(range: any) {
        let position = range.getBoundingClientRect();
        let scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let topOffset = 25;
        let leftOffset = 90;
        let top = position.top + scrollTop - topOffset;
        let left = position.left + (position.width - leftOffset) / 2;
        $(".quote-block").offset({top: top, left: left}).css('visibility', 'visible');
    }

    sendMsgWithoutCheck() {
        this.checkService.doSuccess();
    }

    get literaIframe(): boolean {
        return this.checkService.isChecking();
    }

    closeIframe() {
        this.checkService.stop();
    }

    reloadIframe() {
        this.checkService.reload();
    }

    translateQuoteText(text: string = this.quoteText) {
        this.chatMessagesDataservice.translate(null, text);
    }

    hasTranslatedText(): boolean {
        return this.store.ticketData.translatedText != null;
    }

    removeTranslatedText() {
        this.store.ticketData.translateText(null);
    }

    focusNoteTextarea() {
        setTimeout(() => {
            if (this.noteTextArea) {
                this.noteTextArea.nativeElement.focus();
            }
        }, 100);
    }
}
